import { Observable } from 'rxjs';
import { jwtDecode } from 'jwt-decode';

import { AuthLevel, Authorize } from '../private/authorize.decorator';
import { ICourseCodeReposResponse } from '../private/code-repo-api/code-repo-api.interface';
import { IApiUrls } from '../private/global.interface';
import {
  AxiosInstanceType,
  SlAxiosInstanceService,
} from '../private/services/sl-axios-instance.service';
import { SlApiContext } from '../private/api-context';
import { IDataTrackingApi } from '../private/data-tracking-api/data-tracking-api.interface';
import {
  Service, Container, IBaseDataTracking, TrackingEventNames, StorageService, TokenKey,
} from '../../symphony';

@Service()
export class DataTrackingApi implements IDataTrackingApi {
  private environmentUrl: string;

  private axiosInstance: AxiosInstanceType;

  private storageService = Container.take('global', StorageService);

  private deviceId: string = null;

  constructor() {
    this.environmentUrl = (
      Container.take('global', 'envUrl') as IApiUrls
    ).dynamicTrackingHost;
    this.axiosInstance = Container.take(
      SlApiContext,
      SlAxiosInstanceService,
    ).axios;
  }

  private getDeviceId = (): string => {
    if (this.deviceId) {
      return this.deviceId;
    }

    try {
      const token: string = this.storageService.load(TokenKey.accessToken, 'local');
      const tokenData = jwtDecode(token);
      const userData = JSON.parse((tokenData as any).sid);
      this.deviceId = String(userData.DeviceId);
    } catch (_) {
      return '';
    }

    return this.deviceId;
  };

  @Authorize(AuthLevel.public)
  public track(
    trackingData: IBaseDataTracking[],
  ): Observable<ICourseCodeReposResponse> {
    const eventsWithDeviceId = [
      TrackingEventNames.landingPageImpression,
      TrackingEventNames.landingPageClick,
      TrackingEventNames.joinWaitlist,
      TrackingEventNames.aiPaywallsImpression,
      TrackingEventNames.aiPaywallsClick,
      TrackingEventNames.paywallImpression,
      TrackingEventNames.paywallClick,
      TrackingEventNames.paywallSectionView,
      TrackingEventNames.manageSubscriptionClick,
      TrackingEventNames.cancellationFlowClick,
      TrackingEventNames.cancellationFlowImpression,
      TrackingEventNames.cancellationFlowSurveyResponse,
      TrackingEventNames.webToAppOpen,
      TrackingEventNames.webToAppStart,
      TrackingEventNames.webToAppPageImpression,
      TrackingEventNames.webToAppPageComplete,
      TrackingEventNames.webToAppQuizAnswer,
      TrackingEventNames.webToAppClick,
      TrackingEventNames.webToAppCheckpointCompleted,
      TrackingEventNames.webToAppEmailProvide,
      TrackingEventNames.webToAppSubscribe,
      TrackingEventNames.webToAppPaymentInitialize
    ];

    const data = trackingData.map((item) => {
      if (eventsWithDeviceId.includes(item.event_name)) {
        return {
          ...item,
          device_id: this.getDeviceId(),
        };
      }
      return item;
    });

    return this.axiosInstance.post(this.environmentUrl, data);
  }
}
